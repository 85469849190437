import React from 'react'
import CareInstruction from "../../components/v2/CareInstruction";
import care_instructions_info from '../../pages/v2/jsons/care_instructions.json';

const CareInstructions = ({ care_instructions }) => {

  /* console.log("CARE INSTRUCTIONS:", care_instructions); */

  const getCareInstruction = (code) => {
    const care = care_instructions_info.find((care) => care.code === code);
    return care ? care : { code: 'Unknown', description: 'Unknown Instruction' }; // Add a fallback
  };

  // Function to extract all care codes from the `care_instructions` object or list
  const extractCareCodes = (care_instructions) => {
    // Case 1: If care_instructions is an array of strings (care codes)
    if (Array.isArray(care_instructions)) {
      if (typeof care_instructions[0] === 'string') {
        return care_instructions; // Directly return the list of care codes
      }
      // Case 2: If care_instructions is a list containing an object with categories (e.g., washing, ironing)
      if (typeof care_instructions[0] === 'object') {
        const categories = ['washing', 'ironing', 'drying', 'bleaching', 'professional'];
        let allCodes = [];
        
        categories.forEach((category) => {
          if (Array.isArray(care_instructions[0][category])) {
            allCodes = [...allCodes, ...care_instructions[0][category]];
          }
        });

        return allCodes;
      }
    }

    // Case 3: If care_instructions is an object with categories (e.g., washing, ironing)
    if (typeof care_instructions === 'object') {
      const categories = ['washing', 'ironing', 'drying', 'bleaching', 'professional'];
      let allCodes = [];

      categories.forEach((category) => {
        if (Array.isArray(care_instructions[category])) {
          allCodes = [...allCodes, ...care_instructions[category]];
        }
      });

      return allCodes;
    }

    // Return an empty array if the format is not recognized
    return [];
  };

  const careCodes = extractCareCodes(care_instructions); // Handle all formats

  return (
    <div>
      <div style={{ width: "100%" }} className="div-careinstructions">
        <div className="div-careinstructions-content">
          {careCodes.map((code, index) => (
            <CareInstruction
              key={index}
              careinstruction={getCareInstruction(code)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CareInstructions;
