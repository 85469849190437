import React from 'react'
import "./CareInstruction.css";

//import das defs dos care instructions
//find care instruction that was passed to the component and retrieve data

//componente para mostrar uma careInstruction
// tem como parametros o produto mas lê a lista com uma img (base64) e uma description
/* const product = {
  dpp: {
    care_instructions: [
      { img: "./images/icon_sewing.svg", description: "Cuidados 1" },
      { img: "./images/icon_sewing.svg", description: "Cuidados 2" },
      { img: "./images/icon_sewing.svg", description: "Cuidados 3" },
      { img: "./images/icon_sewing.svg", description: "Cuidados 4" },
    ],
  },
}; */

const CareInstruction = ({ careinstruction }) => {

  /* console.log("careinstruction solo", careinstruction) */

  return (
    <div className="div-careinstructions-v2">
      <div className="div-careinstructions-content-v2">
          <div className="careinstruction-container-v2">
            <img
              className="careinstruction-img"
              alt="careinstructio-img"
              src={careinstruction.img}
            />
            <div className="careinstruction-description-v2">
              {careinstruction.description.en}
            </div>
          </div>
       
      </div>
    </div>
  );
};

export default CareInstruction;
