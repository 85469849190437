import React from 'react';
import './Properties.css';

// Recebe um tipo de categoria e o seu respectivo valor
const Properties = ({ categories }) => {
  // Filter para o environmental
  const environmentalNames = ['Reciclability', 'Microplastics', 'Dangerous Chemicals'];
  const environmentalCategories = categories.filter(category => 
    environmentalNames.includes(category.name)
  );

  // Filter para o general
  const generalCategories = categories.filter(category =>
    !environmentalNames.includes(category.name) && category.name !== 'Variants'
  );

  return (
    <div className="properties-full-container">
      <div className="properties-title-map">
        <div className="properties-subtitle">General</div>
        <div className="categoria-container">
          {generalCategories.map((category, index) => (
            <div key={category.id || index} className="categoria-item">
              <div className="item-info">
                <p className="name">{category.name}</p>
                <p className="properties-description">{category.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="properties-line"></div>
      <div className="properties-title-map">
        <div className="properties-subtitle">Environmental</div>
        <div className="categoria-container">
          {environmentalCategories.map((category, index) => (
            <div key={category.id || index} className="categoria-item">
              <div className="item-info">
                <p className="name">{category.name}</p>
                <p className="properties-description">{category.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Properties;
