import React, { useState } from 'react';
import './Journey.css';
//imports dos jsons para mapear tradução
import prod_actvs from '../../pages/v2/jsons/prod_actvs.json';
import countries from '../../pages/v2/jsons/countries.json';
import compositions from '../../pages/v2/jsons/compositions.json';

//o que recebo
/*   const steps = [
    {
      code: "RAW_MATERIAL",
      fibers: [
        { code: "Cotton", country: "Portugal", company: "fibra coiso" },
        { code: "Palha", country: "Portugal", company: "fibra tal" },
        { code: "Cortiça", country: "Portugal", company: "fibra cenas" },
      ],
    },
    {
      code: "SPINNING",
      country: "Portugal",
      company: "texteis coiso",
    },
    {
      code: "KNITTING",
      country: "Portugal",
      company: "texteis coiso",
    },
    {
      code: "WEAVING",
      country: "Portugal",
      company: "texteis coiso",
    },
    {
      code: "DYEING_AND_FINISHING",
      country: "Portugal",
      company: "texteis coiso",
    },
    {
      code: "SEWING",
      country: "Portugal",
      company: "texteis coiso",
    },
    {
      code: "CLIENT",
      country: "Portugal",
      company: "texteis coiso",
    },
  ]; */

const Journey = ({ steps }) => {
  const [expandedStep, setExpandedStep] = useState(null);
  const [expandedFiber, setExpandedFiber] = useState(null);

  const reverseSteps = (steps) => {
    // Check if the first element's production_activity_code is 'PA_FIBER'
    if (steps[0].production_activity_code !== 'PA_FIBER') {
      return [...steps].reverse(); // Reverse the list if the first element is not 'PA_FIBER'
    }
    return steps; // Return the original list if the first element is 'PA_FIBER'
  };
  
  // Apply the reverse function to your list
  const reversedSteps = reverseSteps(steps);
  

  //-----------------VERIFICAÇÕES--------------------
  const getAPDescription = (code) => {
    const ap = prod_actvs.find((ap) => ap.code === code);
    return ap ? ap.description.en : "Unknown Activity";
  };

  const getCountryDescription = (code) => {
    const country = countries.find((country) => country.code === code);
    return country ? country.description.en : "Unknown Country";
  };

  /* console.log(steps); */

  const getFiberDescription = (code) => {
    /* console.log(code); */
    const fiber = compositions.find((fiber) => fiber.code === code);
    return fiber ? fiber.description.en : "Undefined";
  };

  const codeMapping = {
    PA_FIBER: {
      img: "./images/icon_raw_material_v2_journey.svg",
    },
    PA_SPINNING: {
      img: "./images/icon_spinning_v2_journey.svg",
    },
    PA_KNITTING: {
      img: "./images/icon_knitting_v2_journey.svg",
    },
    PA_FINISHING: {
      img: "./images/icon_dyeing_and_finishing_v2_journey.svg",
    },
    PA_WEAVING: {
      img: "./images/icon_weaving_v2_journey.svg",
    },
    PA_ULTIMATION: {
      img: "./images/icon_ultimation_journey.svg",
    },
    PA_MANUFACTURING: {
      img: "./images/icon_sewing_v2_journey.svg",
    },
    PA_CLIENT: { img: "./images/icon_client_v2_journey.svg" },
  };

  const getTitleAndImage = (code) => {
    return codeMapping[code] || { img: "icon_unknown.svg" };
  };

  const toggleExpand = (index) => {
    setExpandedStep((prev) => (prev === index ? null : index));
  };

  const toggleFiberExpand = (fiberIndex) => {
    setExpandedFiber((prev) => (prev === fiberIndex ? null : fiberIndex));
  };

  return (
    <div className="journey-map-container">
      <div className="vertical-line"></div>
      <div className="journey-map">
        {reversedSteps.map((step, index) => {
          const { img } = getTitleAndImage(step.production_activity_code);
          const isExpanded = expandedStep === index;
          return (
            <React.Fragment key={index}>
              <div className="journey-map-step">
                <img src={img} alt="icon-step" className="icon-step" />
                <div className="step-show-details">
                  <div
                    className="journey-map-step-title-container"
                    onClick={() => toggleExpand(index)}
                  >
                    <div
                      className={`journey-map-step-title ${
                        isExpanded ? "expanded" : ""
                      }`}
                    >
                      {getAPDescription(step.production_activity_code)}
                    </div>
                    <div
                      className={`journey-map-step-plus ${
                        isExpanded ? "expanded" : ""
                      }`}
                    >
                      <span className="plus-sign">+</span>
                    </div>
                  </div>
                  <div
                    className={`step-details ${isExpanded ? "expanded" : ""}`}
                  >
                    {step.production_activity_code === "PA_FIBER" ? (
                      <div className="fibers-container">
                        {step.companies.map((fiber, fiberIndex) => (
                          <div key={fiberIndex} className="fiber-item">
                            <div className="separator-journey-container">
                              <div className="separator-journey"></div>
                            </div>
                            <div
                              className="fiber-title-container"
                              onClick={() => toggleFiberExpand(fiberIndex)}
                            >
                              <div className="fiber-title">
                                {getFiberDescription(fiber.fiber)}
                              </div>
                              <span
                                className={`journey-map-step-plus ${
                                  expandedFiber === fiberIndex ? "expanded" : ""
                                }`}
                              >
                                <span className="plus-sign">+</span>
                              </span>
                            </div>
                            <div
                              className={`fiber-details ${
                                expandedFiber === fiberIndex ? "expanded" : ""
                              }`}
                            >
                              <div className="separator-journey-container">
                                <div className="separator-journey"></div>
                              </div>
                              <div className="journey-expanded-column">
                                <div className="step-text-company">
                                  {fiber.name
                                    ? fiber.name
                                    : "Company Not Provided"}
                                </div>
                                <div className="step-text-country">
                                  {fiber.country
                                    ? getCountryDescription(fiber.country)
                                    : "Country Not Provided"}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {step.companies.map((object, index) => (
                          <div className="journey-expanded-separator">
                            <div className="separator-journey-container">
                              <div className="separator-journey"></div>
                            </div>
                            <div className="journey-expanded-column">
                              <div className="step-text-company">
                                {object.name
                                  ? object.name
                                  : "Company Not Provided"}
                              </div>
                              <div className="step-text-country">
                                {object.country
                                  ? getCountryDescription(object.country)
                                  : "Country Not Provided"}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Journey;
