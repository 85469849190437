import React from "react";
import "./BillOfMaterials.css";
import boms from "../../pages/v2/jsons/bom.json";
import materials from "../../pages/v2/jsons/materials.json";

const BillOfMaterials = ({ data }) => {
  const getBOM = (code) => {
    const bom = boms.find((bom) => bom.code === code.replace("ACCESSORY_", "ACCESSORY_"));
    return bom ? bom.description.en : "Unknown Instruction";
  };

  const getMaterialDescription = (materialCode) => {
    if (!materialCode) return "Unknown Material";
    const material = materials.find((mat) => mat.code === materialCode);
    return material ? material.description.en : "Unknown Material";
  };

  const getTitleImageForType = (type, isLast) => {
    const baseClass = isLast ? "type-bom-last" : "type-bom";
    const iconMap = {
      ACCESSORY_BUTTON: "icon_button_accessorie.svg",
      ACCESSORY_PEARL_SEQUIN: "icon_bling_accessorie.svg",
      ACCESSORY_ZIPPER: "icon_zipper_accessorie.svg",
      ACCESSORY_RIVET_EYELETS: "icon_rivet_accessorie.svg",
      ACCESSORY_TAPE: "icon_bow_accessorie.svg",
      ACCESSORY_CORD: "icon_cord_accessorie.svg",
    };

    const iconSrc = `./images/${iconMap[type] || "icon_button_accessorie.svg"}`;
    
    return (
      <td className={baseClass}>
        <img className="acessory-bom-icon" src={iconSrc} alt="" />
        <span className="span-bom-text">{getBOM(type)}</span>
      </td>
    );
  };

  return (
    <div className="tabela-container">
      <table className="tabela">
        <thead>
          <tr className="tabela-heads">
            <th className="head-type">Type</th>
            <th className="head-quantity">Quantity</th>
            <th className="head-material">Material</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const isLast = index === data.length - 1;
            const quantity = item.quantity ?? "N/A";
            const material = Array.isArray(item.material) ? item.material[0] : item.material;
            return (
              <tr key={index}>
                {getTitleImageForType(item.code, isLast)}
                <td className={isLast ? "quantity-last" : "quantity"}>{quantity}</td>
                <td className={isLast ? "material-last" : "material"}>{getMaterialDescription(material)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BillOfMaterials;
